import React from "react";
import { FaWhatsapp } from "react-icons/fa";
import insta from "../assets/instagram (1).png";
import linkedIn from "../assets/linkedIn.png";
import facebook from "../assets/facebook (1).png";

const Footer = () => {
  return (
    <div className="footer py-5">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-4 col-lg-4">
            <h3 className="sub-heading">About Rudra</h3>
            <p className="text-justify normal-text">
              RUDRA GLOBAL is promoted by a team of young entrepreneurs and
              experienced veterans with proven track records. The group started
              its journey in 1991 with a steel re-rolling mill having capacity
              of 1000 MT per month. Today, the group is engaged in various
              businesses like Ship Recycling, Billats, Industrial Pipes, TMT
              Bars.
            </p>

            {/* <p className="mb-d-none">Copyright &copy; 2016 Rudra TMX. All Rights Reserved.</p> */}
          </div>
          <div className="col-12 col-md-4 col-lg-4 get-in-touch">
            <div className="inner-get-in-touch">
              <h3 className="sub-heading">Get In Touch</h3>
              {/* <p className="normal-text">
                Phone: <a href="tel:079-40370112">079-40370112</a>
              </p> */}
              <p className="normal-text">
                Mobile: <a href="tel:+91 8238341111">+91 8238341111</a>, <br />{" "}
                <a href="tel:+91 8238051111">+91 8238051111</a>
              </p>
              <p className="normal-text">
                Email: <a href="mailto: Info@rudratmx.com">Info@rudratmx.com</a>
              </p>

              <br />

              <div className="social-media-icons">
                <a
                  href="https://www.instagram.com/rudra.global/"
                  target="_blank"
                >
                  <img src={insta} alt="" />
                </a>
                <a
                  href="https://www.linkedin.com/company/rudra-global/"
                  target="_blank"
                >
                  <img src={linkedIn} />
                </a>
                <a href="https://www.facebook.com/rudraglobal" target="_blank">
                  <img src={facebook} alt="" />
                </a>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-4 col-lg-4">
            <h3 className="sub-heading">Rudra Global</h3>
            <address>
              <p className="normal-text">
                Plot No. D-60, Rudra House, <br />
                2nd Floor, Nr.Ram Mantra Mandir,
                <br />
                Kaliabid, Bhavnagar-364002.
              </p>
            </address>
          </div>

          <a href="https://bintech.services/" target="_blank">
            <p className="mb-d-text normal-text text-center mt-5">
              Website Designed and Developed by Team BinTech
            </p>
          </a>
        </div>
      </div>
      <div className="whatsapp-icon">
        <a href="https://api.whatsapp.com/send/?phone=918238341111&text&type=phone_number&app_absent=0">
          <FaWhatsapp />
        </a>
      </div>
    </div>
  );
};

export default Footer;
